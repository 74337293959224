<template>
    <div class="flex-grow overflow-y-auto scroll-bar flex justify-center px-4 py-4">
        <section class="w-full">
            <div class="flex flex-1 items-center justify-between py-1 select-none cursor-pointer text-blue-600  text-sm" @click="onExpandedAll()">
                <p class="font-bold text-lg text-gray-600">Dependent Fields</p>
                <div class="hover:opacity-80">
                    <template v-if="isAllExpanded">Collapse All</template>
                    <template v-else>Expand all</template>

                </div>
            </div>
            <div class="my-3 flex flex-col items-center justify-center relative overflow-hidden border rounded select-none" v-for="(field, indx) in allFields" :key="field.id">
                <div class="p-1 bg-blue-100 w-full flex justify-between items-center cursor-pointer" @click="onExpanded(indx)">
                    <div class="flex items-center">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-blue-500">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                        </svg> -->

                        <h4 class="font-medium text-sm text-blue-500 ml-4">{{ field.name }}</h4>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 transform" :class="{ 'rotate-180': field.expanded }" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
                <div v-if="field.expanded" class="w-full bg-white p-2 transition-all duration-300 ease-out">
                    <div class="flex gap-2 flex-wrap">
                        <div v-if="get(field, 'permissions', []).length" class="shadow overflow-auto scroll-bar flex-grow flex-shrink-1 rounded border border-solid border-gray-300">
                            <table class="min-w-full divide-y divide-gray-200 border border-solid border-gray-100 mb-2">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                        <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Read</th>
                                        <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Write</th>
                                        <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Visible</th>
                                        <th scope="col" class="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Mandatory</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(role, id) in field.permissions" :key="id" class="visibility_row">
                                        <td class="px-2 py-1 text-sm flex items-center gap-2"><Checkbox @input="handleSelectAllFieldPermission(role, indx)" v-model="role.select_all" /> {{ role.title }}</td>
                                        <td class="px-1 py-2 text-center whitespace-nowrap">
                                            <input :disabled="role.role_write || role.role_mandatory" @change="PermisionChange(role, indx)" type="checkbox" class="checkbox checkbox-primary" v-model="role.role_read" />
                                        </td>
                                        <td class="px-1 py-2 text-center whitespace-nowrap">
                                            <input :disabled="role.role_mandatory" @change="PermisionChange(role, indx)" type="checkbox" class="checkbox checkbox-primary" v-model="role.role_write" />
                                        </td>
                                        <td class="px-1 py-2 text-center whitespace-nowrap">
                                            <input :disabled="role.role_write || role.role_mandatory || role.role_read" @change="PermisionChange(role, indx)" type="checkbox" class="checkbox checkbox-primary" v-model="role.role_visible" />
                                        </td>
                                        <td class="px-1 py-2 text-center whitespace-nowrap">
                                            <input @change="PermisionChange(role, indx)" type="checkbox" class="checkbox checkbox-primary" v-model="role.role_mandatory" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p v-else class="text-center py-2 text-gray-500">No Roles Found</p>
                        <div v-if="get(field, 'permissions', []).length" class="shadow overflow-hidden flex-grow flex-shrink-1 rounded border border-solid border-gray-300">
                            <table class="min-w-full divide-y divide-gray-200 border border-solid border-gray-100 mb-2">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Report Visibility</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="role in allReportsRoles" :key="role.id">
                                        <td class="px-1" colspan="5">
                                            <div class="form-control">
                                                <label class="label justify-start cursor-pointer gap-x-4">
                                                    <input type="radio" class="flex-shrink-0 h-6 w-6 text-dvbrandcolor" :name="field.id" :value="role.id" v-model="field.report_visibility" @change="updateFieldLabel(field)" />
                                                    <span class="label-text">{{ role.name }}</span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- <div class="flex items-start justify-end p-2" v-if="indx == 0">
                        <div class="flex h-5 items-center">
                            <input id="comments" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                        </div>
                        <div class="ml-2">
                            <label for="comments" class="font-medium text-gray-700 text-sm">Permissions</label>
                            <p class="text-gray-500 text-xs">Apply these permissions to all the fields</p>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Checkbox from "@/components/checkbox";
import { get, cloneDeep } from "lodash";

export default {
    name: "dependant-fields",
    components: {
        Checkbox,
    },
    props: {
        fields: {
            type: Array,
            default: () => [],
        },
        allReportsRoles: {
            type: Array,
            default: () => [],
        },
        checkData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            allFields: [],
        };
    },
    computed: {
        isAllExpanded() {
            return this.allFields.every((field) => field.expanded);
        },
    },
    methods: {
        get,
        onExpanded(indx) {
            this.allFields[indx].expanded = !this.allFields[indx].expanded;
            this.$forceUpdate();
        },
        onExpandedAll() {
            this.allFields = this.allFields.map((field) => ({ ...field, expanded: !this.isAllExpanded }));
        },
        async handleSelectAllFieldPermission(role, indx) {
            this.allFields[indx].permissions.forEach((_, index) => {
                if (this.allFields[indx].permissions[index].id === role.id) {
                    if (role.select_all) {
                        this.allFields[indx].permissions[index].role_read = true;
                        this.allFields[indx].permissions[index].role_write = true;
                        this.allFields[indx].permissions[index].role_visible = true;
                        this.allFields[indx].permissions[index].role_mandatory = true;
                    } else {
                        this.allFields[indx].permissions[index].role_read = false;
                        this.allFields[indx].permissions[index].role_write = false;
                        this.allFields[indx].permissions[index].role_visible = false;
                        this.allFields[indx].permissions[index].role_mandatory = false;
                    }
                }
            });
            await this.updateFieldLabel(this.allFields[indx]);
        },
        async PermisionChange(role, indx) {
            if (role.role_mandatory) {
                role.role_read = true;
                role.role_write = true;
                role.role_visible = true;
            } else if (role.role_write) {
                role.role_read = true;
                role.role_visible = true;
            } else if (role.role_read) {
                role.role_visible = true;
            }
            await this.updateFieldLabel(this.allFields[indx]);
        },
        async updateFieldLabel(field) {
            this.$emit('updateDependentsFieldLabel', field)
        },
    },
    mounted() {
        this.allFields = cloneDeep(this.fields).map((field) => {
            const obj = field;
            // if (indx === 0) {
            //     obj.expanded = true;
            // } else {
            obj.expanded = false;
            // }
            obj.permissions = obj.permissions.map((per) => ({ ...per, select_all: per.role_mandatory && per.role_read && per.role_visible && per.role_write }));
            return obj;
        });
    },

    // (role.role_mandatory && role.role_read && role.role_visible && role.role_write)

    // created() {
    //     const unwatch = this.$watch("fields", (newFields) => {
    //         if (newFields.length > 0) {
    //             this.allFields = newFields;
    //             unwatch();
    //         }
    //     });
    // },
};
</script>

<style style="scss" scoped></style>
